import * as React from 'react';
import './style.scss';
import * as containerStyles from '../Container/style.module.scss';
import { CustomerCarousel } from '../Carousels/CustomerCarousel';
import { PromoBanner, PromoBannerHomepage } from '../PromoBanner';
import Container from '../Container';
import { DOCS_URL, REQUEST_DEMO_ANCHOR } from 'core/links';
import { defaultButtonText } from 'components/RequestADemoModal/constants';
import images from '../../../images/homepage/index';
import { Helmet } from 'react-helmet';
import ArrowRightIcon from '../../../images/homepage/arrow-right.svg';
import { StaticImage } from 'gatsby-plugin-image';
import * as buttonStyles from '../Pricing/style.module.scss';

export const Homepage: React.FunctionComponent = () => {
  return (
    <section id="homepage" className="mt-32 container">
      <Helmet>
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "image": "https://www.stackhawk.com/stackhawk-long-dark@2x.png",
          "url": "https://www.stackhawk.com",
          "sameAs": ["https://twitter.com/stackhawk", "https://www.linkedin.com/company/stackhawk/mycompany/", "https://www.facebook.com/StackHawk/", "https://github.com/kaakaww"],
          "logo": "https://www.stackhawk.com/stackhawk-long-dark@2x.png",
          "name": "StackHawk, Inc.",
          "description": "Deploy secure applications with StackHawk. Find and fix application security bugs in the build pipeline. Built for developers to own their AppSec",
          "email": "support@stackhawk.com",
          "telephone": "+1-855-KAA-KAWW",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "1580 N. Logan St Ste 660, PMB 36969",
            "addressLocality": "Denver, CO",
            "addressCountry": "US",
            "addressRegion": "western United States",
            "postalCode": "80203"
          }
        },
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": "https://www.stackhawk.com/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://www.stackhawk.com/search/?{search_term_string}/"
            },
            "query-input": "required name=search_term_string"
        },
        {
          "@context": "https://schema.org",
          "@type": "VideoObject",
          "name": "API Security Testing for Teams that Deploy Software Every Day",
          "description": "StackHawk is the only modern DAST and API security testing tool that runs in CI/CD, enabling developers to quickly find, triage, and fix security issues before they hit production.
    ",
          "thumbnailUrl": [
            "https://www.stackhawk.com${images.overviewThumb}",
           ],
          "uploadDate": "2016-03-31T08:00:00+08:00",
          "duration": "PT1M54S",
          "contentUrl": "https://fast.wistia.net/embed/iframe/r86p949tsx",
          "embedUrl": "https://www.stackhawk.com/",
          "interactionStatistic": {
            "@type": "InteractionCounter",
            "interactionType": { "@type": "WatchAction" },
            "userInteractionCount": 5647018
          },
          "regionsAllowed": "US,NL"
        } 
    
    `}</script>
      </Helmet>
      <div className="px-4">
        <div className="row py-4">
          <div className="flex flex-col text-lg-left text-center py-16 homepageHero col-lg-6">
            <h1 className="text-7xl font-extrabold leading-15">
              The <span>Ultimate API Security Testing Platform</span> for Modern Teams
            </h1>
            <p className="max-w-4xl">
              StackHawk’s modern DAST solution has led the way in shift-left security. Now you can use StackHawk to
              discover all of your APIs and applications to better understand your attack surface. Fix security bugs
              faster with frequent testing earlier in the software delivery lifecycle, and reach continuous security
              status as you keep pace with the change of code deployments.
            </p>
            <div className="mb-4 py-2">
              <a href="/demo/">
                <button className="heroButton relative overflow-hidden rounded-insane bg-gradient-to-r from-primary to-secondary text-white p-0.5 hover:opacity-80">
                  <div className="shine"></div>
                  <div className="bg-background h-full w-full p-2 rounded-insane"> Watch a Demo </div>
                </button>
              </a>
            </div>
          </div>
          <div className="text-lg-right text-center flex flex-col col-lg-6 align-items-stretch">
            <div className="align-items-middle w-auto mt-4">
              <iframe
                src="https://fast.wistia.net/embed/iframe/r86p949tsx?seo=true&videoFoam=true"
                title="StackHawk Product Overview"
                allow="fullscreen"
                allowTransparency="true"
                frameBorder="0"
                scrolling="no"
                className="wistia_embed"
                name="wistia_embed"
                msallowfullscreen="true"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center text-center mt-6">
          <h2 className="font-extrabold text-2xl mb-0 hawksomeCustomers">Our Awesome Customers</h2>
          <CustomerCarousel />
        </div>

        <PromoBanner />

        <div className="textSection text-center">
          <h2>
            <span className="textPrimary">Supercharge</span> your
            <br /> AppSec Team
          </h2>
          <p>
            StackHawk enables dev teams to automate application security testing within their local development and
            pre-production workflows.
          </p>
          <a href="/demo/">
            <button className="textPrimary getStartedButton hoverWiggle">
              See StackHawk in Action{' '}
              <em>
                <img src={images.ArrowRightIcon} alt="Right Arrow Icon" />
              </em>
            </button>
          </a>
        </div>
        <div className="">
          <div className={containerStyles.wrapper}>
            <section
              className={`${containerStyles.homepageCommon} ${containerStyles.wide}
            ${containerStyles.enableDevs} ${'enableDevs'}`}
            >
              <div className={`${containerStyles.leftSide}`}>
                <div className={`${containerStyles.wideTextContainer}`}>
                  <img className={containerStyles.topIcon} src={images.codeIcon} alt="Enable Developers icon" />
                  <h3>Enable Developers</h3>
                  <p>
                    Give development teams the right tools to test their APIs and applications to shift security left
                  </p>
                  <a href={DOCS_URL}>
                    <button className={'getStartedButton hoverWiggle'}>
                      Read the Docs{' '}
                      <em>
                        <img src={ArrowRightIcon} alt="Right Arrow Icon" />
                      </em>
                    </button>
                  </a>
                </div>
              </div>
              <div className={`${containerStyles.rightSide}`}>
                <StaticImage
                  as="div"
                  src="../../../images/homepage/enable-devs@2x.png"
                  layout="constrained"
                  className={containerStyles.staticImageContainer}
                  imgClassName={containerStyles.staticImageItem}
                  alt="Give development teams the right tools to test their APIs and applications to shift security left"
                />
              </div>
            </section>
          </div>

          <div className="grid">
            <div
              className={`${containerStyles.wrapper} ${containerStyles.grid} ${containerStyles.gridTwoThirds} ${containerStyles.cicdWorkflow}`}
            >
              <section className={`${containerStyles.twoThirds} ${containerStyles.homepageCommon}`}>
                <div className={containerStyles.leftSide}>
                  <img
                    className={`${containerStyles.topIconBigger}`}
                    src={images.CICDWorkflowsIcon}
                    alt="Built for CI/CD Workflows icon"
                  />
                  <h3>Built for CI/CD Workflows</h3>
                  <p className={''}>Automated security testing built for CI/CD workflows</p>
                </div>
                <div className={containerStyles.rightSide}>
                  <StaticImage
                    imgStyle={{
                      width: '74%',
                      left: '250px',
                    }}
                    style={{
                      position: 'unset',
                    }}
                    as="div"
                    src="../../../images/homepage/cicd-workflows@2x.png"
                    layout="constrained"
                    className={containerStyles.staticImageContainer}
                    imgClassName={containerStyles.staticImageItem}
                    alt="Automated security testing built for CI/CD workflows"
                  />
                </div>
              </section>
            </div>
            <Container
              icon={images.StackHawkIcon}
              heading="Build Trust"
              description="Build trust & eliminate disruptive triaging and blocked releases"
              button1Text="Learn More"
              button1Link="https://www.stackhawk.com/lp/otr-stackhawk-for-dast-and-api-security-testing/"
              variant="oneThird"
              cssClass="buildTrust"
            />
          </div>
          <Container
            description="StackHawk is my dream boat of an API security service!"
            variant="quotation"
            quoteAuthor="— James Berthoty, Senior Cloud Security Engineer, ReliaQuest"
          />
        </div>
        <div className="textSection text-center">
          <h2>
            Ship Secure <span className="textPrimary">Code at Scale</span>
          </h2>
          <p>
            Faster, safer development with automated web application security testing as part of everyday software
            testing workflows
          </p>
          <a href="/product/">
            <button className="textPrimary getStartedButton hoverWiggle">
              Learn More{' '}
              <em>
                <img src={images.ArrowRightIcon} alt="Right Arrow Icon" />
              </em>
            </button>
          </a>
        </div>
        <div className={`${containerStyles.alteredGrid} alteredGrid`}>
          <div
            className={`${containerStyles.wrapper} ${containerStyles.grid} ${containerStyles.gridTwoThirds} ${containerStyles.twoRows}`}
          >
            <section className={`${containerStyles.twoThirds} ${containerStyles.homepageCommon}`}>
              <div className={containerStyles.leftSide}>
                <img className={`${containerStyles.topIcon}`} src={images.BadgeCheck} alt="Verify and Ship icon" />
                <h3>Verify and Ship</h3>
                <p className={''}>
                  Verify secure code on every single build and release applications to production faster
                </p>
                <a href="https://www.stackhawk.com/blog/how-does-stackhawk-work/">
                  <button className={'getStartedButton hoverWiggle'}>
                    How Stackhawk Works{' '}
                    <em>
                      <img src={ArrowRightIcon} alt="Right Arrow Icon" />
                    </em>
                  </button>
                </a>
              </div>

              <div className={containerStyles.rightSide}>
                <StaticImage
                  style={{
                    position: 'unset',
                  }}
                  as="div"
                  src="../../../images/homepage/verify-and-ship@2x.png"
                  layout="constrained"
                  className={containerStyles.staticImageContainer}
                  imgClassName={containerStyles.staticImageItem}
                  alt="Verify secure code on every single build and release applications to production faster"
                />
              </div>
            </section>
          </div>
          <Container
            icon={images.TestLocallyIcon}
            heading="Test Locally"
            description="Test locally and continuously within CI/CD workflows improving quality and time to release"
            variant="oneThird"
            cssClass="noMarginBottom"
          />
          <Container
            icon={images.InteroperabilityIcon}
            heading="Interoperability"
            bottomIcons={[images.gitHubIcon, images.jiraIcon, images.azureIcon]}
            description="Utilize existing developer workflows and tools including GitHub, JIRA, AzureDevOps, Snyk, AWS and more..."
            variant="oneThird"
          />
        </div>

        <div className="textSection text-center">
          <h2>
            <span className="textPrimary">Manage Risk</span> Appropriately
          </h2>
          <p>Automated API security testing that streamlines results in real-time for faster alerts and faster fixes</p>
        </div>
        <div className={`${containerStyles.alteredGridRight} alteredGrid grid mt-6 pb-4`}>
          <Container
            icon={images.TriageIcon}
            heading="Triage"
            description="Focus and fix the most critical vulnerabilities by categorizing findings based on their severity and impact"
            variant="oneThirdReverse"
            cssClass="noMarginBottom"
          />
          <Container
            icon={images.ReduceNoiseIcon}
            heading="Reduce Noise"
            description="Identify and prioritize actionable insights with fine-tune scanning across your technology stack"
            variant="oneThirdReverse"
          />
          <div
            className={`${containerStyles.wrapper} ${containerStyles.grid} ${containerStyles.gridTwoThirds} ${containerStyles.twoRowsReverse}`}
          >
            <section className={`${containerStyles.twoThirds} ${containerStyles.homepageCommon}`}>
              <div className={containerStyles.leftSide}>
                <img className={`${containerStyles.topIcon}`} src={images.ApiCoverageIcon} alt="API Coverage icon" />
                <h3>Complete API Coverage</h3>
                <p className={''}>
                  Customized API security testing ensures complete and accurate coverage across REST, GraphQL, gRPC, and
                  SOAP APIs
                </p>
              </div>

              <div className={containerStyles.rightSide}>
                <StaticImage
                  style={{
                    position: 'unset',
                  }}
                  as="div"
                  src="../../../images/homepage/api-coverage@2x.png"
                  layout="constrained"
                  className={containerStyles.staticImageContainer}
                  imgClassName={containerStyles.staticImageItem}
                  alt="API Coverage Image"
                />
              </div>
            </section>
          </div>
        </div>

        <div className="containerPaddingMedium">
          <div className={`${containerStyles.wrapper}`}>
            <section
              className={`${containerStyles.homepageCommon} ${containerStyles.wide} ${containerStyles.mobileHideImg}`}
            >
              <div className={`${containerStyles.leftSide5050}`}>
                <div className={`${containerStyles.wideTextContainer} ${'pt-0'}`}>
                  <h3 className={''}>How StackHawk is advancing API & application security testing</h3>
                  <StaticImage
                    style={{
                      position: 'unset',
                      display: 'none',
                    }}
                    imgStyle={{
                      left: 'unset',
                      bottom: 'auto',
                      paddingRight: '3rem',
                      marginTop: '2rem',
                      paddingLeft: '2rem',
                    }}
                    as="div"
                    src="../../../images/homepage/advancing-security-testing@2x.png"
                    layout="constrained"
                    className={containerStyles.staticImageContainer}
                    imgClassName={containerStyles.staticImageItem}
                    alt="Advanced Security Testing"
                  />

                  <p>
                    Leverage proactive API and application security testing with StackHawk. Accelerate the delivery of
                    quality, security-tested releases, minimize business disruptions, and confidently manage the risk of
                    unknown vulnerabilities running in your applications.
                  </p>
                  <a href="https://www.stackhawk.com/solutions/dast/">
                    <button className={buttonStyles.btnPrimary}>Learn More </button>
                  </a>
                </div>
              </div>
              <div className={containerStyles.rightSide}>
                <StaticImage
                  style={{
                    position: 'unset',
                    bottom: 'auto',
                    paddingRight: '3rem',
                    marginTop: '2rem',
                    paddingLeft: '2rem',
                  }}
                  imgStyle={{
                    left: 'unset',
                    height: 'unset',
                    bottom: 'auto',
                    paddingRight: '3rem',
                    marginTop: '6%',
                    paddingLeft: '2rem',
                  }}
                  as="div"
                  src="../../../images/homepage/advancing-security-testing@2x.png"
                  layout="constrained"
                  className={containerStyles.staticImageContainer}
                  imgClassName={containerStyles.staticImageItem}
                  alt="Advanced Security Testing"
                />
              </div>
            </section>
          </div>
        </div>
        <div className="containerPaddingMedium">
          <PromoBannerHomepage />
        </div>

        <div className="containerPaddingLarge">
          <div className={`${containerStyles.wrapper}`}>
            <section
              className={`${containerStyles.homepageCommon} ${containerStyles.wide} ${containerStyles.mobileHideImg} `}
            >
              <div className={`${containerStyles.leftSide5050}`}>
                <div className={`${containerStyles.wideTextContainer} ${'wide5050' && 'pt-0'}`}>
                  <h3 className={`${containerStyles.demoh3}`}>Interested in seeing StackHawk at work?</h3>
                  <p className={`${containerStyles.ctaSub}`}>Schedule time with our experts for a live demo.</p>
                  <a href={REQUEST_DEMO_ANCHOR}>
                    <button className={buttonStyles.btnPrimary}>{defaultButtonText} </button>
                  </a>
                </div>
              </div>
              <div className={`${containerStyles.rightSide}`}>
                <StaticImage
                  style={{
                    position: 'unset',
                  }}
                  imgStyle={{
                    left: 'unset',
                  }}
                  as="div"
                  src="../../../images/homepage/sh-in-flight@2x.png"
                  layout="constrained"
                  className={`${containerStyles.staticImageContainer}`}
                  imgClassName={`${containerStyles.staticImageItem}`}
                  alt="StackHawk in Flight"
                />
              </div>
            </section>
          </div>
        </div>
        <div className="containerPaddingSmall">
          <Container
            heading="Get Hands-On Experience <br/> <span>Give Us A Test Drive!</span>"
            description="We know you might want to test drive a full version of security software before you talk to us. So, Get It On!"
            button1Text="Start Your Free Trial"
            button1Link="https://auth.stackhawk.com/signup"
            cssClass="hawksomeCard"
          />
        </div>
      </div>
    </section>
  );
};
